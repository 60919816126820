const createAccountVideo = 'https://youtu.be/mXGmHurskJg';
const orderingVideo = 'https://www.youtube.com/watch?v=URhpbCt2HC0&amp;t=13s';
const factoringVideo = {
  Chile: 'https://www.youtube.com/watch?v=gvd8Ejr_qyo&t=3s',
  Mexico: 'https://youtu.be/q7MsMS7J600',
};

export {
  createAccountVideo,
  orderingVideo,
  factoringVideo,
};
